.table-order{
  &__custom{
    .table-order{
      &-name{
        width: 350px;;
      }

      &-price{
        width: 145px;
      }
    }

    td.table-order{
      &-price{
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}