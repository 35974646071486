.popup {
  width: 567px;
  height: auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 29px rgba(32, 22, 0, 0.24);
  position: fixed;
  left: 50%;
  margin-left: -283px;
  padding: 28px 18px;
  visibility: hidden;
  opacity: 0;
  box-sizing: border-box;
  top: 210px;
  z-index: 10;
  font-family: 'AgoraSansProRegular', Arial, sans-serif;
  z-index: 200;
}

span.popup-close {
  width: 26px;
  height: 26px;
  background-image: url('../img/general/sprice-delete.png');
  background-repeat: no-repeat;
  background-position: -20px -20px;
  display: inline-block;
  cursor: pointer;
  right: 18px;
  top: 20px;
  position: absolute;
}

span.popup-close:hover {
  background-position: -86px -20px;
}

.overlay {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.58);
  transition: 0.2s;
}

.popup-title {
  font-size: 18px;
  font-weight: 900;
  line-height: 10px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 25px;
  font-family: "AgoraSansProBlack", Arial;
}

.popup-position {
  border-bottom: 2px solid #ebebeb;
  margin-bottom: 50px;
  transition: 0.2s;
}

.popup-position-image {
  border: 1px solid #ebebeb;
  border-bottom: none;
 /* width: 89px;*/
  height: 126px;
  margin-right: 35px;
  display: inline-block;
  vertical-align: middle;
}

.popup-position-link {
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  display: inline-block;
  color: black !important;
  vertical-align: middle;
}

.popup-position-btns {
  text-align: center;
}

.popup-position-btns-delete {
  width: 182px;
  height: 45px;
  background-color: #4faf7c;
  border-radius: 7px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  color: #fff !important;
  text-decoration: none !important;
  cursor: pointer;
}

.popup-position-btns-cancel {
  width: 212px;
  height: 45px;
  background-color: #fff;
  border-radius: 7px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #4faf7c;
  display: inline-block;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  color: #4faf7c !important;
  text-decoration: none !important;
  cursor: pointer;
}