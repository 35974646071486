
// fonts
@import 'fonts.scss';

@import 'vars.scss';
@import 'mixins.scss';

// libs here
// @import 'normalize.scss';
@import './libs/suggestions.scss';
@import 'legacy.scss';

// common styles
@import 'common.scss';

// components here
// @import '../../components/bitrix/simple.component/simple.component.scss';

@import 'cart.scss'; // корзина старая. стили будут удалены с внедрением заказов Битрикса

@import "custom.scss"; // кастомные старые стили стили