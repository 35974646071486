body {
	position: relative;
	margin: 0;
	padding: 0;
}

a {
	color: #000;
	transition: 0.2s;

	&:hover {
		color: #0195F5;
	}
}

.rt-container {
	width: 1240px;
	margin: 0 auto;
	font-family: 'PFAgoraSansPro-Regular';

	&_flex {
		display: flex;
		justify-content: space-between;
	}
}

.rt-content {
	display: inline-block;
	width: 990px;
}

.rt-aside {
	display: inline-block;
	width: 212px;
	vertical-align: top;
}

.rt-section {
	position: relative;	
	margin-bottom: 33px;
	padding: 33px;
	border: 2px solid #ebebeb;

	&_borderless {
		border: none;
	}

	&_order {
		padding: 29px;
	}

	&__title {
		margin-top: 0;
		margin-bottom: 15px;
		font-family: 'PFAgoraSansPro-Bold';
		font-size: 18px;
	}

	&__detail {
		margin: 0;
		margin-bottom: 10px;
		font-size: 14px;
		line-height: 25px;

		&:last-of-type {
			margin-bottom: 18px;
		}
	}

	&__detail-value {
		&_bold {
			font-family: 'PFAgoraSansPro-Bold';
		}
	}

	&__delete {
		position: absolute;
		top: 33px;
		right: 33px;
	}
}

.rt-menu {
	margin: 0;
	padding: 0;
	list-style: none;
	
	&__item {
		height: 50px;
		border-bottom: 1px solid #9F9FA0;
		box-sizing: border-box;
		background-color: #515152;

		&_active {
			background-color: #0195F5;
		}
	}

	&__link {
		display: block;
		padding-left: 30px;
		font-family: 'PFAgoraSansPro-Bold';
		font-size: 16px;
		line-height: 50px;
		color: #fff;
		text-decoration: none;
	}
}

.rt-title {
	font-family: 'PFAgoraSansPro-Black';
	font-size: 30px;
	margin-top: 0;
	margin-bottom: 36px;
}

.rt-text {
	font-size: 14px;
	color: #000;
	
	&_red {
		color: #FF0000;
	}

	&_bold {
		font-size: 18px;
		font-family: 'PFAgoraSansPro-Bold';
	}

	&_description {
		margin-top: -5px;
	}

	&_list-title {
		margin-top: 65px;
		margin-bottom: 16px;
	}

	&_profile {
		margin: 0;
		line-height: 25px;
	}
}

.rt-list {
	padding-left: 15px;
	font-size: 14px;
	margin-bottom: 39px;
	margin-top: 0;

	&__item {
		margin-bottom: 8px;
	}
}

.rt-button {
	display: inline-block;
	width: 300px;
	border: none;
	border-radius: 10px;
	background-color: #00B17B;
	font-family: 'PFAgoraSansPro-Bold';
	font-size: 16px;
	line-height: 45px;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	color: #fff;
	cursor: pointer;
	transition: 0.2s;
	box-sizing: border-box;

	&:hover {
		background-color: #558c44;
		color: #fff;
	}

	&:active, &:focus {
		outline: none;
		background-color: #38662b;
	}

	&:disabled {
		background-color: #E0E0E0;
		cursor: default;
	}

	&_thin {
		background-color: transparent;
		border: 2px solid #00B17B;
		color: #00B17B;

		&:hover {
			border: 2px solid #558c44;
		}
	}

	&_red {
		background-color: #EB5757;
		&:hover {
			background-color: rgb(182, 47, 47);
		}
	}

	&_order-repeat {
		margin-top: 33px;
	}
}

.rt-delete {
	width: 12px;
	height: 16px;
	
	border: none;
	background-color: transparent;
	background-image: url('/local/templates/reatorg_new/static/img/general/trash.png');
	background-position: 0 0;
	background-repeat: no-repeat;
	transition: 0.2s;
	cursor: pointer;

	&:hover {
		background-image: url('/local/templates/reatorg_new/static/img/general/trash-hover.png');
	}

	&:active, &:focus {
		outline: none;
	}
}

.rt-form {
	width: 570px;
	
	&_popup {
		width: 100%;
	}

	&_order {
		width: 100%;
	}

	&_delete-order {
		width: 100%;
	}

	&__fieldset {
		margin: 0;
		margin-top: 37px;
		padding: 0;
		border: none;

		&_checkboxes {
			margin-top: 39px;
		}
	}

	&__input-wrapper {
		display: inline-block;
		&_size {
			&_s {
				width: 48%;
			}

			&_m {
				width: 572px;
			}

			&_l {
				width: 773px;
			}
		}
	}

	&__row {
		display: flex;
		justify-content: space-between;

		&_mb {
			margin-bottom: 33px;
		}
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
	}
}

.rt-input {
	position: relative;
	display: block;
	margin-bottom: 32px;

	&:last-child {
		margin-bottom: 24px;
	}

	&_required {
		.rt-input__label:after {
			content: '*';
			color: #FF0000;
		}
	}

	&_suggestion-direction {
		&_left {
			.suggestions-suggestions {
				right: 0;
			}
		}
	}

	&_checkbox, &_radio {
		.rt-input__input {
			display: none;
		}

		.rt-input__label {
			position: relative;
			padding-left: 31px;
			margin-bottom: 0;
			font-family: 'PFAgoraSansPro-Regular';
			font-size: 14px;
			cursor: pointer;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				display: inline-block;
				width: 20px;
				height: 20px;
				margin-top: -2px;
				border: 1px solid #dcddde;
				box-sizing: border-box;
				background-color: #ebebeb;
				box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.07);
				transition: 0.2s;
			}
		}
	}

	&_checkbox {
		margin-bottom: 22px;

		&:last-of-type {
			margin-bottom: 34px;
		}

		.rt-input__label {
			&:before {
				border-radius: 5px;
			}
		}

		.rt-input__input {
			&:checked {
				& + .rt-input__label:before {
					background-color: #3ab07a;
					background-image: url('/local/templates/reatorg_new/static/img/general/check.svg');
					background-repeat: no-repeat;
					background-position: 50% 50%;
				}
			}
		}

		.rt-input__error {
			left: 100%;
			white-space: nowrap;
			margin-left: 20px;
		}
	}

	&_radio {
		display: inline-block;
		margin-right: 104px;
		margin-bottom: 14px;
		.rt-input__input {
			&:checked {
				& + .rt-input__label:before {
					box-shadow: inset 0 0 0 4px #3ab07a;
					background-color: #fff;
				}
			}
		}

		.rt-input__label {
			font-family: 'PFAgoraSansPro-Bold';
			font-size: 16px;
			&:before {
				border-radius: 50%;
			}
		}
	}

	&__label {
		display: inline-block;
		font-family: 'PFAgoraSansPro-Bold';
		font-size: 16px;
		margin-bottom: 14px;

		&-italic {
			font-style: italic;
		}
	}

	&__error {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 14px;
		color: #FF0000;
		text-align: right;
	}

	&__input {
		display: block;
		width: 100%;
		height: 45px;
		padding: 0 18px;
		font-family: 'PFAgoraSansPro-Regular';
		font-size: 14px;
		line-height: 45px;
		border: 2px solid #EBEBEB;
		background-color: #FFFFFF;
		box-sizing: border-box;
		transition: 0.2s;
		font-weight: normal;

		&:focus {
			outline: none;
			border: 2px solid rgb(161, 161, 161);
		}

		&_error {
			border: 2px solid #ff0000;

			+ .rt-input__link_forgot {
				opacity: 1;
				font-size: 14px;
			}
		}

		&:disabled {
			background-color: rgba(242, 242, 242, 0.5);
			border: 2px solid #EBEBEB;
		}
	}

	&__link {
		position: absolute;
		top: 100%;
		margin-top: 8px;
		padding: 0;
		font-size: 14px;
		line-height: 17px;
		border: none;
		background-color: transparent;
		text-decoration: underline;
		cursor: pointer;
		transition: 0.2s;

		&:hover {
			color: #0195F5;
		}

		&:focus {
			outline: none;
		}

		&_left {
			left: 0;
		}
	}

	&__description {
		position: absolute;
		right: 0;
		display: block;
		margin-top: 8px;
		font-size: 14px;
		text-align: right;
	}
}

.rt-timer {
	&__button {
		margin-bottom: 19px;
	}
}

.rt-goods-list {

	&_personal {
		.rt-goods-list__item-content {
			vertical-align: middle;
			&:nth-child(5) {
				width: 158px; // count
			}
	
			&:nth-child(6) {
				width: 163px; // price
				padding-right: 18px;
			}
		}
	}

	&__header {
		height: 46px;
		background-color: #2095FD;
		margin-bottom: 22px;

		.rt-goods-list__item-content {
			font-family: 'PFAgoraSansPro-Bold';
			color: #fff;
			line-height: 46px;
			font-size: 14px;
			text-transform: uppercase;
		}
	}

	&__breaker {
		height: 22px;
	}

	&__item {
		height: 131px;
		transition: 0.2s;

		&-content {
			border-bottom: 2px solid #EBEBEB;
		}

		&:hover {
			box-shadow: 0px 0px 9px rgba(32, 22, 0, 0.22);
		}
	}
	
	&__item-content {
		box-sizing: border-box;
		font-size: 18px;

		&_left {
			text-align: left;
		}

		&_right {
			text-align: right;
		}

		&:nth-child(1) {
			width: 54px;
			padding-left: 16px;
		}

		&:nth-child(2) {
			width: 111px; // num
		}

		&:nth-child(3) {
			width: 143px; // image
			padding-left: 18px;
		}

		&:nth-child(4) {
			width: 355px; // title, 330 original
		}

		&:nth-child(5) {
			width: 176px; // count
		}

		&:nth-child(6) {
			width: 145px; // price
		}
 
		&:nth-child(7) {
			width: 167px; // sum
		}

		&:nth-child(8) {
			width: 101px; // delete
		}
	}

	&__item-delete {
		margin-right: 27px;
	}
}

.rt-counter {
	font-size: 0;

	&__button {
		display: inline-block;
		height: 41px;
		width: 41px;
		background-color: #ebebeb;
		border: none;
		font-size: 22px;
		transition: 0.2s;
		cursor: pointer;

		&:hover {
			background-color: #00B17B;
			color: #fff;
		}
	}

	&__input {
		height: 43px;
		width: 74px;
		margin-right: 4px;
		margin-left: 4px;
		box-sizing: border-box;
		border: 2px solid #ebebeb;
		font-size: 22px;
		text-align: center;
	}
}

.rt-address {
	position: relative;

	&_ur {
		.rt-address__checkbox:last-of-type {
			margin-bottom: 5px;
		}

		.rt-address__value {
			font-size: 14px;
			line-height: 25px;

			.rt-text_bold {
				font-size: 14px;
			}
		}
	}

	&__checkbox {
		display: inline-block;

		&:last-of-type {
			margin-bottom: 15px;
		}
	}

	&__value {
		width: 890px;
		margin-left: 30px;
	}

	&__text {
		margin: 0;
		margin-top: 8px;
		margin-left: 30px;
	}

	&__return {
		position: relative;
		font-size: 14px;
		background-color: transparent;
		border: none;
		margin-bottom: 15px;
		padding: 0;
		padding-left: 30px;
		color: #ff0000;

		&:before, &:after {
			content: '';
			position: absolute;
			top: 0;
			left: 8px;
			display: block;
			width: 2px;
			height: 16px;
			background-color: #ff0000;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}

.rt-addresses-form{
	margin-bottom: 15px;

	.rt-addresses-form-save{
		display: none;
	}
}

.rt-add-address {
	display: none;
	padding-top: 0;
	padding-left: 63px;

	&_ur {
		.rt-input {
			margin-bottom: 8px;
		}

		.rt-text {
			line-height: 25px;
			margin-bottom: 10px;
		}
	}
}

.rt-order-total {
	margin-right: 101px;
	text-align: right;
	font-size: 18px;
	line-height: 32px;
}

.rt-select {
	position: relative;
	cursor: pointer;
	
	&_open {
		.rt-select__list {
			display: block;
		}

		.rt-select__button:before {
			transform: rotate(180deg);
		}
	}

	&__value {
		display: block;
		width: 100%;
		height: 45px;
		padding: 0 18px;
		font-size: 14px;
		line-height: 45px;
		border: 2px solid #EBEBEB;
		background-color: #FFFFFF;
		box-sizing: border-box;
		transition: 0.2s;
		font-weight: normal;
	}

	&__button {
		position: absolute;
		top: 2px;
		right: 2px;
		width: 43px;
		height: 41px;
		padding: 0;
		border: none;
		background-color: #EBEBEB;
		text-align: center;
		cursor: pointer;

		&:before {
			content: '';
			display: inline-block;
			width: 22px;
			height: 13px;
			margin-top: 7px;
			background-image: url('/local/templates/reatorg_new/static/img/general/chevron.png');
			background-repeat: no-repeat;
			background-position: 50% 50%;
			transition: 0.2s;
		}

		&:focus {
			outline: none;
		}
	}

	&__list {
		position: absolute;
		display: none;
		top: 43px;
		left: 0;
		width: 100%;
		margin: 0;
		padding: 0;
		border: 2px solid #EBEBEB;
		box-sizing: border-box;
		list-style: none;
		z-index: 1;
	}

	&__list-item {
		display: flex;
		align-items: center;
		height: 45px;
		padding: 0 18px;
		overflow: hidden;
		font-size: 14px;
		background-color: #fff;
		box-sizing: border-box;
		font-weight: normal;

		&:hover {
			background-color: #EBEBEB;
		}
	}
}

.rt-mask {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.58);
}

.rt-popup {
	position: relative;
	left: 50%;
	margin-left: -350px;
	padding: 33px;

	&_confirm {
		width: 700px;
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
	}
}


.rt-content-personal{
	width: 990px;
	margin: 0;

	.rt-container {
		width: 990px;
	}
}

.b-aside-menu.left-menu-personal > ul > li
{
	width: 210px;
}

.b-catalog-item-text {
	a.b-catalog-item-text__price-link{
		font: 400 15px/22px "PFAgoraSansPro-Medium";
		color: #ff0000;
		text-decoration: none;
		display: inline-block;
		margin: 0;
	}
}
a.b-catalog-item-text__price-link{
	font: 400 19px/22px "PFAgoraSansPro-Medium";
	color: #ff0000;
	text-decoration: none;
	display: inline-block;
	margin: 0;
}
