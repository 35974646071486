.custom{
  &__popup{
    &-position{
      &-btns{
        &-delete{
          width: 217px;
        }
      }

      &-link{
        width: 320px;
      }
    }

    &-product{
      &-picture{
        &-link{
          display: inline-block;
        }
      }
    }
  }

  &__rt-auth-link{
    color: #0a7ddd;
  }
}

.checked-order-info-req {
  display: inline-block;
  position: relative;
  top: -7px;
}

.rt-order-info-block{
  margin-bottom: 37px;
  font-size: 18px;
  padding: 20px 33px 20px 33px;
  &__full-sum {
    display: block;
    &-value{
      font-weight: 700;
      color: #ff0000;
    }
  }

  &__info{
    font-size: 16px;
    &-small{
      font-size: 14px;
      font-style: italic;
    }
    &-warning{
      color: #ff0000;
      font-size: 18px;
    }
  }

  div{
    line-height: 28px;
  }
}
.b-content-no-aside{
  float: none;
}

.order-info .readonly {
  cursor: no-drop;
}

.rt-input__input[readonly],
.order-info-inputs-element input[readonly]{
  background-color: #f8f8f8;
}

.rt-list-type{
  &-view{
    width: 100%;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    height: 40px;
    display: -webkit-flex;
    -webkit-flex-direction: row-reverse;
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 0 5px 0;
    &-list{
      width: 40px;
      height: 40px;
      margin-left: 10px;
      background: url(../img/general/list.png) no-repeat;
      cursor: pointer;
      &:hover{
        background: url(../img/general/list-active.png) no-repeat;
      }
      &.active{
        background: url(../img/general/list-active.png) no-repeat;
      }
    }
    &-plate{
      width: 40px;
      height: 40px;
      margin-left: 10px;
      background: url(../img/general/plate.png) no-repeat;
      cursor: pointer;
      &:hover{
        background: url(../img/general/plate-active.png) no-repeat;
      }
      &.active{
        background: url(../img/general/plate-active.png) no-repeat;
      }
    }

    &-cnt-product-page{
      margin-left: 10px;
      width: 55px;
    }
  }
}