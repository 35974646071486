@font-face {
  font-family: 'PFAgoraSansPro-Bold';
  src: url("../fonts/PFAgoraSansPro-Bold.woff"); 
  font-style: normal; 
  font-weight: bold;
}

@font-face {
  font-family: 'PFAgoraSansPro-Regular';
  src: url("../fonts/AgoraSansProRegular.woff"); 
  font-style: normal; 
  font-weight: normal;
}

@font-face {
  font-family: 'PFAgoraSansPro-Black';
  src: url("../fonts/AgoraSansProBlack.woff"); 
  font-style: normal; 
  font-weight: bold;
}